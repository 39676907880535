import validator from 'is_js';

const checkEmpty = (val, key) => {
    if (validator.empty(val.trim())) {
        return `${key}`;
    } else {
        return '';
    }
}

const checkMinLength = (val, minLength, key) => {
    if (val.trim().length < minLength) {
        return `${key} must be ${minLength} characters long.`
    } else {
        return '';
    }
}

export default function (data) {
    const { fname,lname, email, password,file } = data

    if (fname !== undefined) {
        let emptyValidationText = checkEmpty(fname, 'enter First name.')
        if (emptyValidationText !== '') {
            return emptyValidationText;
        } else {
            let minLengthValidation = checkMinLength(fname, 3, 'First Name')
            if (minLengthValidation !== '') {
                return minLengthValidation
            }
        }
    }
    if (lname !== undefined) {
            if(!lname) return
            let minLengthValidation = checkMinLength(lname, 3, 'Last name')
            if (minLengthValidation !== '') {
                return minLengthValidation
            }
        
    }

    if (email !== undefined) {
        let emptyValidationText = checkEmpty(email, 'Please enter email')
        if (emptyValidationText !== '') {
            return emptyValidationText;
        } else {
            // if (!validator.email(email)) {
            //     return 'Please enter valid email'
            // }
        }
    }


    if (password !== undefined) {
        let emptyValidationText = checkEmpty(password, 'Please enter password')
        if (emptyValidationText !== '') {
            return emptyValidationText;
        } else {
            let minLengthValidation = checkMinLength(password, 6, 'password')
            if (minLengthValidation !== '') {
                return minLengthValidation
            }
        }
    }

    if (file !== undefined) {
       if(!file) return "please Selct an image"
       if (!file.type.startsWith('image/')) return 'Please select a image file.'
      
    }

}

