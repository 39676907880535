import types from 'redux/types';
import {
  CAST_VOTE,
  CREATE_CANDIDATE,
  CREATE_POSITION,
  CREATE_VOTER,
  DELETE_CANDIDATE,
  DELETE_POSITION,
  DELETE_VOTER,
  GET_CANDIDATESS,
  GET_CANDIDATES_LIST,
  GET_CHART_DATA,
  GET_DASHBOARD_DATA,
  GET_POSITIONS,
  GET_VOTERS,
  GET_VOTES,
  UPDATE_CANDIDATE,
  UPDATE_POSITION,
  UPDATE_VOTER,
  VOTE_STATUS,
} from '../../config/urls';
import { apiGet, apiPost, dataToFormData } from '../../utils/utils';
import store from '../store';

const { dispatch } = store;

export function fetchvoters(data) {
  return apiGet(GET_VOTERS, data);
}

export function createvoters(data) {
  let formData = dataToFormData(data);
  return apiPost(CREATE_VOTER, formData);
}

export function updatevoters(data) {
  let formData = dataToFormData(data);
  return apiPost(UPDATE_VOTER, formData);
}

export function deleteVoter(data) {
  return apiPost(DELETE_VOTER, data);
}

// positions


export function fetchPositions() {
  return new Promise((resolve, reject) => {
    return apiGet(GET_POSITIONS)
      .then((res) => {
        if (res?.success) {
          resolve(res);
          dispatch({
            type: types.SAVE_POSITION,
            payload: res?.positions||[],
          });
          return;
        }
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function createPosition(data) {
  return apiPost(CREATE_POSITION, data);
}

export function updatePosition(data) {
  return apiPost(UPDATE_POSITION, data);
}

export function deletePosition(data) {
  return apiPost(DELETE_POSITION, data);
}

// Candidate
export function fetchCandidates() {
   return apiGet(GET_CANDIDATESS)
}

export function createCandidate(data) {
  let formData = dataToFormData(data);
  return apiPost(CREATE_CANDIDATE, formData);
}

export function updateCandidate(data) {
  let formData = dataToFormData(data);
  return apiPost(UPDATE_CANDIDATE, formData);
}

export function deleteCandidate(data) {
  return apiPost(DELETE_CANDIDATE, data);
}

// Vote
export function fetchVotes() {
  return apiGet(GET_VOTES)
}
export function voteCast(data) {
  return apiPost(CAST_VOTE, data);
}
export function voteStatus() {
  return apiGet(VOTE_STATUS);
}

// Dashboard
export function fetchDashboard() {
  return apiGet(GET_DASHBOARD_DATA)
}

export function fetchCandidateList() {
  return apiGet(GET_CANDIDATES_LIST)
}


// Chart 
export function fetchChartData() {
  return apiGet(GET_CHART_DATA)
}
