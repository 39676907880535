import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { getToken } from "./localStorage";

export const ProtectedRoute = (admin) => {
  const navigate = useNavigate();
  const auth = getToken();
  // console.log(auth);
  // console.log(admin);
  if (!auth?.access_token) {
   return <Navigate to="/sign-in" />
    // return;
  }

  if (admin.admin && auth?.role !== "admin") {
   return <Navigate to="/sign-in" />
   
  }
  return (
    <>
      <Outlet />
    </>
  );
};
