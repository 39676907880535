
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import actions from "redux/actions";
import { useEffect, useState } from "react";




function Dashboard() {
  const [data, setData] = useState({})
  const [chartData, setChartData] = useState([])
  
  useEffect(() => {
    fetchData();
    fetchChartData()
  }, []);
   const fetchData = async () => {
    try {
      const res = await actions.fetchDashboard();
      if (!!res?.success) {
        setData(res?.data)
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchChartData = async () => {
    try {
      const res = await actions.fetchChartData();
      if (!!res?.success) {
        console.log(res)
        setChartData(res?.chartData)
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="how_to_vote"
                title="Votes"
                count={data?.totalVotes||0}
                percentage={{
                  color: "success",
                  // amount: "+55%",
                  label: "Total votes",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="how_to_reg"
                title="Voters"
                count={data?.totalVoter||0}
                percentage={{
                  color: "success",
                  // amount: "+3%",
                  label: "Total voters",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="ballot"
                title="Positions"
                count={data?.totalPositions||0}
                percentage={{
                  color: "success",
                  // amount: "+1%",
                  label: "Total Positions",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="groups"
                title="Candidates"
                count={data?.totalCandidate||0}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Total Candidates",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={5} justifyContent={'space-evenly'} >
          {chartData?.map((chart)=>(
          <Grid  key={chart?.position} item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <HorizontalBarChart
                  color="success"
                  title={chart?.position}
                  chart={{
                    labels:chart?.candidates?.map((item)=>item?.name)||[],
                    datasets: [
                      {
                        label: 'Votes',
                        data: chart?.candidates?.map((item)=>item?.count)||[],
                        color:"info"
                      },
                    ],
                  }}
                />
              </MDBox>
            </Grid>))}
           
          </Grid>
        </MDBox>

      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
