export const API_BASE_URL = "https://e-voting-app-8e0b436f9dc5.herokuapp.com/api/v2";
export const IMAGE_BASE_URL = "https://e-voting-app-8e0b436f9dc5.herokuapp.com/";
export const getApiUrl = (endpoint) => API_BASE_URL + endpoint

// DashBoard 
export const GET_DASHBOARD_DATA = getApiUrl('/vote/dashboard');
export const GET_CHART_DATA = getApiUrl('/vote/chart');
export const GET_CANDIDATES_LIST = getApiUrl('/candidate/candidate-list');


// Auth
export const LOGIN_URL = getApiUrl('/user/login')
export const SIGNUP_URL = getApiUrl('/signup');

// Vote
export const GET_VOTERS = getApiUrl('/vote/all-voters');
export const GET_VOTES = getApiUrl('/vote/all-votes');
export const CREATE_VOTER = getApiUrl('/vote/create-voter');
export const UPDATE_VOTER = getApiUrl('/vote/update-voter');
export const DELETE_VOTER = getApiUrl('/vote/delete-voter');
export const CAST_VOTE = getApiUrl('/vote/cast-vote');
export const VOTE_STATUS = getApiUrl('/vote/vote-status');

// POSITION
export const GET_POSITIONS = getApiUrl('/position/all-positions');
export const CREATE_POSITION = getApiUrl('/position/create-position');
export const UPDATE_POSITION = getApiUrl('/position/update-position');
export const DELETE_POSITION = getApiUrl('/position/delete-position');


// CANDIDATE
export const GET_CANDIDATESS = getApiUrl('/candidate/all-candidates');
export const CREATE_CANDIDATE = getApiUrl('/candidate/create-candidate');
export const UPDATE_CANDIDATE = getApiUrl('/candidate/update-candidate');
export const DELETE_CANDIDATE = getApiUrl('/candidate/delete-candidate');