export function setItem(key, data) {
    data = JSON.stringify(data);
    localStorage.setItem(key, data);
  }
  
  export function getItem(key) {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  }
  
  export function removeItem(key) {
    localStorage.removeItem(key);
  }
  
  export function clearLocalStorage() {
    localStorage.clear();
  }
  
  export function setToken(data) {
    data = JSON.stringify(data);
    localStorage.setItem('token', data);
  }
  
  export function getToken() {
    const data = localStorage.getItem('token');
 
    return data ? JSON.parse(data) : null;
  }
  
  export function clearToken() {
    localStorage.removeItem('token');
  }
  