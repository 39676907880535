/* eslint react/prop-types: 0 */

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import MDTypography from 'components/MDTypography';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { showError } from 'utils/alert';

const VisuallyHiddenInput = styled('input')({
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
  color: 'primary',
});

const style = {
  position: 'absolute',
  top: '45%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

export default function FormModal({ open, handleClose, initialData, onsubmit, title }) {
  const [state, setState] = React.useState({ ...initialData });
  const {positions} = useSelector(state=>state.vote)
  console.log({initialData,positions})

  const {name, fname, lname, password, email, file, platform, position, avatar,maximum_vote } = state;

  const updateState = (data) => setState(() => ({ ...state, ...data }));
  const handleInputfile = (evt) => {
    const file = evt.target.files[0];
    console.log(file);
    if (file && file.type.startsWith('image/')) {
      console.log('Selected image file:', file);
      updateState({ file: evt.target.files[0] });
    } else {
      showError('only image file allowed');
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={()=>{
          handleClose()
          updateState(initialData)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid>
            <Card style={{ maxWidth: 450, padding: '20px 5px', margin: '0 auto' }}>
              <CardContent>
                <Typography gutterBottom variant="h5">
                  {title}
                </Typography>
                <MDTypography variant="h6" component="p" gutterBottom>
                  Fill up the form.
                </MDTypography>
                <form>
                  
                  <Grid container spacing={1}>
                    {fname !== undefined && (
                      <Grid xs={12} item>
                        <TextField
                          placeholder="Enter first name"
                          label="First Name"
                          variant="outlined"
                          fullWidth
                          required
                          value={fname}
                          onChange={(evt) => {
                            updateState({ fname: evt.target.value });
                          }}
                        />
                      </Grid>
                    )}
                    {lname !== undefined && (
                      <Grid xs={12} item>
                        <TextField
                          placeholder="Enter last name"
                          label="Last Name"
                          variant="outlined"
                          fullWidth
                          required
                          value={lname}
                          onChange={(evt) => {
                            updateState({ lname: evt.target.value });
                          }}
                        />
                      </Grid>
                    )}
                    {name !== undefined && (
                      <Grid xs={12} item>
                        <TextField
                          placeholder="Enter name"
                          label="Name"
                          variant="outlined"
                          fullWidth
                          required
                          value={name}
                          onChange={(evt) => {
                            updateState({ name: evt.target.value });
                          }}
                        />
                      </Grid>
                    )}
                    {email !== undefined && (
                      <Grid item xs={12}>
                        <TextField
                          type="email"
                          placeholder="Enter email"
                          label="Email"
                          variant="outlined"
                          fullWidth
                          required
                          value={email}
                          onChange={(evt) => {
                            updateState({ email: evt.target.value });
                          }}
                        />
                      </Grid>
                    )}
                    {password !== undefined && (
                      <Grid item xs={12}>
                        <TextField
                          type="password"
                          placeholder="Enter Password"
                          label="Password"
                          variant="outlined"
                          fullWidth
                          required
                          value={password}
                          onChange={(evt) => {
                            updateState({ password: evt.target.value });
                          }}
                        />
                      </Grid>
                    )}
                     {maximum_vote !== undefined && (
                      <Grid item xs={12}>
                        <TextField
                          type="number"
                          placeholder="Enter Password"
                          label="Maximum Vote"
                          variant="outlined"
                          fullWidth
                          required
                          value={maximum_vote}
                          onChange={(evt) => {
                            updateState({ maximum_vote: evt.target.value });
                          }}
                        />
                      </Grid>
                    )}

                    {position !== undefined && (
                      <Grid item xs={12}>
                        <FormControl fullWidth size="medium">
                          <InputLabel id="demo-simple-select-helper-label">Position</InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            sx={{ p: 1.4 }}
                            value={position}
                            onChange={(evt) => {
                              updateState({ position: evt.target.value });
                            }}
                            label="position"
                          >
                            {positions.map((item)=> <MenuItem  key={item?._id}value={item}>{item?.name}</MenuItem>)}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                    {platform !== undefined && (
                      <Grid item xs={12}>
                        <TextField
                          label="platform"
                          multiline
                          rows={4}
                          placeholder="Type your message here"
                          variant="outlined"
                          fullWidth
                          required
                          value={platform}
                          onChange={(evt) => {
                            updateState({ platform: evt.target.value });
                          }}
                        />
                      </Grid>
                    )}
                    {(file !== undefined || avatar !== undefined) && (
                      <Grid item xs={12}>
                        <Button
                          component="label"
                          variant="outlined"
                          onChange={handleInputfile}
                          startIcon={<CloudUploadIcon color="info" />}
                        >
                          <Typography
                            variant="body1"
                            color={'GrayText'}
                            fontSize={'12px'}
                            component={'span'}
                          >
                            {file?.name ? file.name : 'Upload picture'}
                          </Typography>
                          <VisuallyHiddenInput type="file" />
                        </Button>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Button
                        onClick={(event) => {
                            event.preventDefault();
                              onsubmit(state);
                          //  updateState(initialData)
                        }}
                        type="submit"
                        variant="contained"
                        fullWidth
                      >
                          <Typography
                            variant="body1"
                            color={'#fff'}
                            fontSize={'15px'}
                            component={'span'}
                          >
                        Submit
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
