import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { Box, Button, Icon, IconButton } from '@mui/material';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import MDBadge from 'components/MDBadge';
import copy from 'copy-to-clipboard';


// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import DataTable from 'examples/Tables/DataTable';
import CreateModel from 'examples/Model';

import { useMaterialUIController } from 'context';
import { useAsyncDebounce } from 'react-table/dist/react-table.development';
import actions from 'redux/actions';
import { IMAGE_BASE_URL } from 'config/urls';
import validations from 'utils/validations';
import { showError } from 'utils/alert';
import { showAlert } from 'utils/alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function Votes() {
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;

 

  const [search, setSearch] = useState('');
  const [voter, setVoter] = useState([]);
  const [select, setSelect] = useState(null);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [updateModel, setupdateModel] = React.useState(false);
  const handleupdateModelOpen = () => setupdateModel(true);
  const handleupdateModelClose = () => {
    setupdateModel(false);
    setSelect(null);
  };

  const handleUpdate = async (data) => {
    try {
      console.log({data})
      if(!data?.password) delete data?.password
      const res = await actions.updatevoters(data);
      if (!!res?.success) {
        showAlert('Voter update successfully');
        setupdateModel();
        fetchData();
      } else {
        showError(res.data.message);
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const handleEdit = (data) => {
    setSelect(data);
    handleupdateModelOpen();
  };

  const handleDelete = async (data) => {
    try {
      const res = await actions.deleteVoter({ _id: data?._id });

      if (!!res?.success) {
        showAlert(res?.message);
        fetchData();
      } else {
        showError(res?.message);
      }
    } catch (error) {
      console.log('error raised during fetch voters', error);
    }
  };
  const handleSubmit = async (data) => {
    try {
      const checkValid = isValidData(data);
      if (!checkValid) return;
      const res = await actions.createvoters(data);
      console.log('res fetch users', res);
      if (!!res?.success) {
        showAlert('Voter Create successfully');
        handleClose();
        fetchData();
      } else {
        showError(res.data.message);
      }
    } catch (error) {
      showError(error.message);
    }
  };

  const isValidData = (data) => {
    const error = validations(data);
    if (error) {
      showError(error);
      return false;
    }
    return true;
  };

  const onSearchChange = useAsyncDebounce((value) => {
    setSearch(value || undefined);
  }, 100);

  const columns = [
    {
      Header: 'Voter',
      accessor: 'VoterCamp',
      value: 'fullname',
      canSearch: true,
      align: 'left',
      // sorted: true,
    },
    {
      Header: 'Voter ID',
      accessor: 'VoterIdCamp',
      value: 'voterId',
      canSearch: true,
      align: 'center',
    },
    {
      Header: 'created At',
      accessor: 'CreatedAtCap',
      value: 'createdAt',
      canSearch: true,
      align: 'center',
    },
    { Header: 'action', accessor: 'action', align: 'center' },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await actions.fetchvoters();
      console.log('res fetch users', res);
      if (!!res?.success) {
        console.log('dasdasd', genrateTableRow(res.voters));
        setVoter(genrateTableRow(res.voters, handleEdit, handleDelete));
      }
    } catch (error) {
      console.log('error raised during fetch voters', error);
    }
  };
  // Function to search within the rows
  function searchRows(rows) {
    if (!search) {
      return rows; // If the search text is empty, return all rows
    }
    let searchText = search.toLowerCase();

    return rows.filter((row) => {
      for (const column of columns) {
        if (row[column.accessor] && column.canSearch) {
          console.log('column found');
          const cellValue = String(row[column.value]).toLowerCase();
          // console.log(cellValue)
          if (cellValue.includes(searchText)) {
            return true; // Return the row if the text is found in any cell
          }
        }
      }
      return false;
    });
  }

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
        {/* <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={2}
                  px={2}
                  variant="gradient"
                  bgColor={sidenavColor}
                  borderRadius="lg"
                  coloredShadow="info"
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <MDTypography variant="h6" color="white">
                    Voter Table
                  </MDTypography>
                  <Box
                    m={0}
                    style={{
                      color: 'white',
                      cursor: 'pointer',
                      boxShadow: '0 25px 50px -5px rgb(0 0 0 / 1)',
                    }}
                    p={0.5}
                    color="white"
                    display={'flex'}
                    borderRadius={100}
                    justifyContent={'center'}
                    alignContent={'center'}
                    bgcolor={'info'}
                    onClick={handleOpen}
                  >
                    <Icon fontSize="medium">add</Icon>
                  </Box>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    canSearch={true}
                    table={{ columns, rows: searchRows(voter) }}
                    isSorted={true}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    search={search}
                    onSearchChange={onSearchChange}
                    noEndBorder
                    pagination={{
                      color: sidenavColor,
                    }}
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
      <CreateModel
        open={open}
        handleClose={handleClose}
        initialData={{ fname: '', lname: '', password: '', file: null }}
        onsubmit={handleSubmit}
        title={'Create Voter'}
      />

      {select ? (
        <CreateModel
          open={updateModel}
          handleClose={handleupdateModelClose}
          initialData={select?{...select,password:null}:{}}
          onsubmit={handleUpdate}
          title={'Update Voter'}
        />
      ) : null}
    </>
  );
}

export default Votes;

const genrateTableRow = (rows, handleEdit, handleDelete) =>
  rows.map((row) => ({
    ...row,
    fullname: `${row?.fname} ${row?.lname}`,
    VoterCamp: (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDAvatar src={IMAGE_BASE_URL + row?.avatar} name={row?.fname} size="sm" />
        <MDBox ml={2} lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {`${row?.fname} ${row?.lname}`}
          </MDTypography>
        {row?.email &&<MDTypography variant="caption">{row?.email}</MDTypography>}
      </MDBox>
      </MDBox>
      
    ),
    VoterIdCamp: (
      <MDBox lineHeight={1}>
        <Tooltip title="Copy" placement="top">
          <Button onClick={()=>{copy(row?.voterId)}}> {row?.voterId}</Button>
        </Tooltip>
      </MDBox>
    ),
    CreatedAtCap: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {new Date(row?.createdAt).toLocaleDateString('fr-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })}
        {/* {Date(row?.createdAt)?.toLocaleDateString()} */}
      </MDTypography>
    ),
    action: (
      <>
        <Tooltip title="Edit">
          <IconButton color="info" onClick={() => handleEdit(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => handleDelete(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    ),
  }));
