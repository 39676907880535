/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Votes from "layouts/votes";
import Voter from "layouts/voter";
import Billing from "layouts/billing";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
// import MaterialTable from "examples/Tables/MaterialTable";

// @mui icons
import Icon from "@mui/material/Icon";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "utils/ProtectedRoute";
import Position from "layouts/position";
import Candidate from "layouts/candidate";
import Ballot from "layouts/ballot";

export const AdminRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Votes",
    key: "votes",
    icon: <Icon fontSize="small">how_to_vote</Icon>,
    route: "/votes",
    component: <Votes />,
  },
  {
    type: "collapse",
    name: "Voters",
    key: "voters",
    icon: <Icon fontSize="small">how_to_reg</Icon>,
    route: "/voters",
    component: <Voter />,
  },
  {
    type: "collapse",
    name: "Positions",
    key: "positions",
    icon: <Icon fontSize="small">poll</Icon>,
    route: "/positions",
    component: <Position />,
  },
  {
    type: "collapse",
    name: "Candidates",
    key: "candidates",
    icon: <Icon fontSize="small">groups</Icon>,
    route: "/candidates",
    component: <Candidate />,
  },
  // {
  //   type: "collapse",
  //   name: "Ballot Positions",
  //   key: "ballot",
  //   icon: <Icon fontSize="small">ballot</Icon>,
  //   route: "/ballot",
  //   component: <Ballot/>,
  // },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: "billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/authentication/sign-in",
  //   component: <SignIn />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
];

const AuthRoutes = [
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/sign-up",
    component: <SignUp />,
  },
];

const VoterRoutes = [
  {
    name: "Cast",
    key: "cast",
    route: "/",
    component: <Ballot />,
    exact:true
  }
];

const getRoutes = (allRoutes) => {
  return allRoutes.map((route) => {
    if (route.collapse) {
      return getRoutes(route.collapse);
    }
    if (route.route) {
      return <Route exact path={route.route} element={route.component} key={route.key} />;
    }
    return null;
  });
};
const Root = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoute admin={true} />}>{getRoutes(AdminRoutes)}</Route>
      {getRoutes(AuthRoutes)}
      <Route element={<ProtectedRoute  />}>{getRoutes(VoterRoutes)}</Route>
    </Routes>
  );
};

export default Root;
