import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { Box, Button, Icon, IconButton } from '@mui/material';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import MDBadge from 'components/MDBadge';

// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import DataTable from 'examples/Tables/DataTable';
import CreateModel from 'examples/Model';

import { useMaterialUIController } from 'context';
import { useAsyncDebounce } from 'react-table/dist/react-table.development';
import actions from 'redux/actions';
import { IMAGE_BASE_URL } from 'config/urls';
import validations from 'utils/validations';
import { showError } from 'utils/alert';
import { showAlert } from 'utils/alert';

function Votes() {
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;

  const [search, setSearch] = useState('');
  const [candidates, setCandidates] = useState([]);
  const onSearchChange = useAsyncDebounce((value) => {
    setSearch(value || undefined);
  }, 100);

  const columns = [
    {
      Header: 'Voter',
      accessor: 'VoterCamp',
      value: 'fullname',
      canSearch: true,
      align: 'left',
      // sorted: true,
    },
    {
      Header: 'Candidate',
      accessor: 'CandidateCamp',
      value: 'candidate',
      canSearch: true,
      align: 'left',
      // sorted: true,
    },
    {
      Header: 'Position',
      accessor: 'PositionCamp',
      value: 'position',
      canSearch: true,
      align: 'left',
    },
    {
      Header: 'created At',
      accessor: 'CreatedAtCap',
      value: 'createdAt',
      canSearch: true,
      align: 'center',
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await actions.fetchVotes();
      if (!!res?.success) {
        setCandidates(genrateTableRow(res.votes));
      }
    } catch (error) {
      console.log('error raised during fetch voters', error);
    }
  };
  function searchRows(rows) {
    if (!search) {
      return rows; // If the search text is empty, return all rows
    }
    let searchText = search.toLowerCase();

    return rows.filter((row) => {
      for (const column of columns) {
        if (row[column.accessor] && column.canSearch) {
          console.log('column found');
          const cellValue = String(row[column.value]).toLowerCase();
          // console.log(cellValue)
          if (cellValue.includes(searchText)) {
            return true; // Return the row if the text is found in any cell
          }
        }
      }
      return false;
    });
  }
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={2}
                  px={2}
                  variant="gradient"
                  bgColor={sidenavColor}
                  borderRadius="lg"
                  coloredShadow="info"
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <MDTypography variant="h6" color="white">
                  Votes Table
                  </MDTypography>
            
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    canSearch={true}
                    table={{ columns, rows: searchRows(candidates) }}
                    isSorted={true}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    search={search}
                    onSearchChange={onSearchChange}
                    noEndBorder
                    pagination={{
                      color: sidenavColor,
                    }}
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default Votes;

const genrateTableRow = (rows) =>
  rows.map((row) => ({
    ...row,
    fullname: `${row?.voter?.fname} ${row?.voter?.lname}`,
    VoterCamp: (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDAvatar src={IMAGE_BASE_URL + row?.voter?.avatar} name={row?.fname} size="sm" />
        <MDBox ml={2} lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
          {`${row?.voter?.fname || ""} ${row?.voter?.lname || ""}`}
          </MDTypography>
        </MDBox>
      </MDBox>
    ),
    position:row?.candidate?.position?.name,
    PositionCamp: (
      <MDTypography display="block" variant="button" fontWeight="medium">
        {row?.candidate?.position?.name}
        </MDTypography>
    ),
    candidate:`${row?.candidate?.fname} ${row?.candidate?.lname}`,
    CandidateCamp: (
      <MDTypography display="block" variant="button" fontWeight="medium">
       { `${row?.candidate?.fname} ${row?.candidate?.lname}`}
        </MDTypography>
    ),
    CreatedAtCap: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {new Date(row?.createdAt).toLocaleDateString('fr-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })}
      </MDTypography>
    ),
  }));
