import types from "../types";

const initial_state = {
    positions: []
}

export default function(state= initial_state, action){
    switch (action.type) {
        case types.SAVE_POSITION:
            const data = action.payload
            return {positions: data}        
        default:
            return {...state}
    }
}