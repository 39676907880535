import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';

import PageLayout from 'examples/LayoutContainers/PageLayout';
import {
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Icon,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import actions from 'redux/actions';
import { useEffect, useState } from 'react';
import MDButton from 'components/MDButton';
import { useMaterialUIController } from 'context';
import MDAvatar from 'components/MDAvatar';
import { IMAGE_BASE_URL } from 'config/urls';
import MDAlert from 'components/MDAlert';
import { showAlert } from 'utils/alert';
import { showError } from 'utils/alert';
import Dialog from 'examples/Dialog';

function Ballot() {
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;

  const [list, setList] = useState([]);
  const [isVoted, setisVoted] = useState(null);
  const [open, setOpen] = useState(false);
  const [preview, setPreview] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, position) => {
    setList((prevList) =>
      prevList.map((item) => {
        if (item._id == position) {
          return { ...item, select: event?.target?.value || '' };
        }
        return item;
      })
    );
  };
  const handleSubmit = async () => {
    try {
      const candidates = list?.map((candi) => candi?.select);
      const candidate = candidates.filter((item) => item !== undefined && item !== null);

      if (Array.isArray(candidate) && candidate?.length > 0) {
        const res = await actions.voteCast({ candidate });
        if (!!res?.success) {
          showAlert('Ballot submited');
          setisVoted(true);
        }
      } else {
        showError('Please select atleast one candidate');
      }
    } catch (error) {
      showError(error?.message);
    }
  };

  useEffect(() => {
    fetchData();
    CheckAlreadySubmit();
  }, []);

  const fetchData = async () => {
    try {
      const res = await actions.fetchCandidateList();
      if (!!res?.success) {
        setList(res.list);
      }
    } catch (error) {
      console.log('error raised during fetch voters', error);
    }
  };
  const CheckAlreadySubmit = async () => {
    try {
      const res = await actions.voteStatus();
      console.log(res);
      if (!!res?.success) {
        setisVoted(res.VoteStatus || false);
        setPreview(res.vote);
      }
    } catch (error) {
      console.log('error raised during fetch voters', error);
    }
  };
  console.log(list);
  if (isVoted == null) {
    return <></>;
  }

  return (
    <PageLayout>
      <DashboardNavbar />
      <MDBox mt={3} display="flex" alignItems="center" justifyContent="center">
        <MDTypography variant="h2" fontWeight="medium">
          Election 2023
        </MDTypography>
      </MDBox>
      {isVoted ? (
        <>
          <MDBox mt={2} display="flex" alignItems="center" justifyContent="center">
            <MDAlert color="success">
              <Icon sx={{ fontSize: 40 }}>done</Icon>&nbsp; Success: Ballot submited
            </MDAlert>
          </MDBox>
          <MDBox
            mt={1}
            display="flex"
            alignItems="center"
            sx={{ flexDirection: 'column', mb: 10 }}
            justifyContent="center"
          >
            <MDTypography variant="h6" fontWeight="medium">
              You have already voted for this election
            </MDTypography>
            <MDButton onClick={handleClickOpen} sx={{ mt: 1.5 }} color="info">
              <Icon sx={{ fontSize: 40 }}>ballot</Icon>&nbsp; View ballot
            </MDButton>
          </MDBox>
        </>
      ) : (
        <>
          {list?.map((item, index) => {
            return (
              <Card key={index} sx={{ my: 5, mx: 7, py: 2, px: 3 }}>
                <MDBox pt={2} lineHeight={1.25}>
                  <MDTypography variant="h6" fontWeight="medium">
                    {item?._id}
                  </MDTypography>
                  <Divider sx={{ m: 0 }} />
                  <MDBox mb={1}>
                    <MDTypography variant="button" color="text">
                      Select only one candidate
                    </MDTypography>
                  </MDBox>
                </MDBox>
                {/* <Divider sx={{ m: 0 }} /> */}
                <MDBox mt={1} px={4} mb={2}>
                  <RadioGroup
                    aria-labelledby={'demo-radio-buttons-group-label' + item?._id}
                    name={item?._id}
                    value={item?.select}
                    onChange={(evt) => handleChange(evt, item?._id)}
                  >
                    {item?.candidates?.map((candidate) => (
                      <MDBox key={candidate?._id} display="flex" alignItems="center" sx={{ my: 2 }}>
                        <FormControlLabel value={candidate?._id} control={<Radio />} />
                        <MDButton
                          color={sidenavColor}
                          coloredShadow="info"
                          size="small"
                          variant="contained"
                          sx={{ m: 0, p: 0, px: 2, fontSize: 9, height: 20, mr: 2 }}
                          onClick={() => {
                            alert(candidate.platform);
                          }}
                        >
                          platform
                        </MDButton>
                        <MDAvatar
                          src={IMAGE_BASE_URL + candidate?.avatar}
                          name={candidate?.fname}
                          variant="rounded"
                          size="xxl"
                          shadow="sm"
                          sx={{ height: 100, width: 100, objectFit: 'cover' }}
                        />
                        <MDTypography
                          variant="body2"
                          fontWeight="medium"
                          sx={{ ml: 3 }}
                        >{`${candidate?.fname} ${candidate?.lname}`}</MDTypography>
                      </MDBox>
                    ))}
                  </RadioGroup>
                </MDBox>
              </Card>
            );
          })}
          <MDBox display="flex" alignItems="center" justifyContent="center" mb={5}>
            <MDButton sx={{ mr: 2 }} onClick={handleClickOpen} color="info">
              <Icon sx={{ fontSize: 40 }}>ballot</Icon>&nbsp; Preview
            </MDButton>
            <MDButton onClick={handleSubmit} color="success">
              <Icon sx={{ fontSize: 40 }}>done</Icon>&nbsp;Submit
            </MDButton>
          </MDBox>
        </>
      )}
      <Dialog open={open} title="Ballot Preview" handleClose={handleClose}>
        {isVoted ? (
          <>
            {preview?.candidate.map((item) => (
              <MDBox key={item?._id} display="flex" alignItems="center" pl={5} mb={1}>
                <MDTypography variant="h6" fontWeight="medium">
                  {item?.position?.name} :
                </MDTypography>
                <MDTypography ml={1} variant="body2">
                  {`${item?.fname || ''} ${item?.lname || ''}`}
                </MDTypography>
              </MDBox>
            ))}
          </>
        ) : (
          <>
            {list.map((item) => {
              let candidate = item?.candidates?.filter(
                (candidate) => item.select == candidate?._id
              )?.[0];
              return (
                <MDBox key={item._id} display="flex" alignItems="center" pl={5} mb={1}>
                  <MDTypography variant="h6" fontWeight="medium">
                    {item._id} :
                  </MDTypography>
                  <MDTypography key={item._id} ml={1} variant="body2">
                    {`${candidate?.fname || ''} ${candidate?.lname || ''}`}
                  </MDTypography>
                </MDBox>
              );
            })}
          </>
        )}
      </Dialog>
      {/* <Footer /> */}
    </PageLayout>
  );
}

export default Ballot;
