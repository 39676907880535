/* eslint react/prop-types: 0 */

import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useMaterialUIController } from 'context';
import { colors } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme,ownerState }) => {
  return({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
})});

export default function CustomizedDialogs({open,handleClose,title,message,children}) {
  const [controller] = useMaterialUIController();
  const { sidenavColor,darkMode } = controller;
  const bgcolor = darkMode ? '#1a2035':"white"
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{borderRadius:12}}
      >
        <DialogTitle sx={{ m: 0, p: 2,bgcolor:bgcolor}} id="customized-dialog-title">
        {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent 
         dividers  sx={{minWidth:400,minHeight:200,bgcolor:bgcolor}}>
          <Typography gutterBottom>
          {message}
          </Typography>
          {children}
        </DialogContent>
        <DialogActions sx={{bgcolor:bgcolor}}>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}